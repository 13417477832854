<template>
  <div class="container">
    <Spinner v-if="loading" />
    <template v-else-if="broker">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body p-5">
              <img v-if="broker.logo" class="w-100" :src="broker.logo" />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card">
            <div class="card-body p-5">
              <div class="fw-bold mb-4">About {{ broker.officeName }}</div>
              <p>
                {{ broker.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h1>Listings from {{ broker.officeName }}</h1>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-sm-6 col-12"
          v-for="listing in broker.listings"
          :key="listing.id"
        >
          <Listing
            :listing="listing"
            :externalClass="['recent-listing']"
            :listingLink="`/listings/${listing.slug}`"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Listing from "@/components/general/listing/Listing.vue";
import Spinner from "../../components/ui/Spinner.vue";

export default {
  components: {
    Listing,
    Spinner,
  },
  data() {
    return {
      broker: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const { data } = await window.axios.get(`/broker/${this.$route.params.office_name}`);
    this.broker = data;
    this.loading = false;
  },
};
</script>
